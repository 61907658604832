import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import * as React from 'react'
import LazyLoad from 'react-lazyload'

import * as Common from '@rushplay/common'

import * as Configuration from './configuration'
import * as ServerConfiguration from './server-configuration'

export function SocialMediaSection() {
  const configuration = ServerConfiguration.useContext()
  const socialMedia = ReactRedux.useSelector(state =>
    Configuration.getSocialMediaLinks(state.configuration)
  )

  return (
    <Common.Box display="inline" height="20px">
      {R.map(
        item => (
          <LazyLoad once height="20px" key={item.name} offset={100}>
            <Common.Box
              as="a"
              rel="noreferrer"
              target="_blank"
              pr="12px"
              mb="12px"
              href={item.links[configuration?.locale?.code?.toLowerCase()]}
            >
              <Common.Box as="img" alt={item.name} src={item.image.url} />
            </Common.Box>
          </LazyLoad>
        ),
        socialMedia
      )}
    </Common.Box>
  )
}
