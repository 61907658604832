import * as ReactRedux from 'react-redux'
import * as React from 'react'
import LazyLoad from 'react-lazyload'

import * as Common from '@rushplay/common'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

import * as Configuration from './configuration'

const scroll = keyframes`
 0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-25%);
    }
`

const ProviderContainer = styled(Common.Box)`
  display: inline-grid;
  justify-items: center;
  align-items: center;
  grid-template-rows: repeat(1, 1fr);
  gap: 40px;
  width: 100%;

  /* Hide scrollbar for Webkit browsers (e.g., Chrome, Safari) */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  animation: ${scroll} linear infinite;
  animation-duration: ${props => props.duration}s;

  @media screen and (max-width: 800px) {
    grid-template-rows: repeat(2, 1fr);
  }
`

export function PaymentProviderImages() {
  const containerRef = React.useRef(null)

  const [duration, setDuration] = React.useState(30) // default scroll duration

  const paymentProviders = ReactRedux.useSelector(state =>
    Configuration.getPaymentProviderImageUrls(state.configuration)
  )

  React.useEffect(() => {
    if (paymentProviders.length > 0) {
      // Adjust scroll duration based on the number of items
      const speedFactor = 0.01 // Adjust this number to control scroll speed
      setDuration(Math.max(paymentProviders.length * speedFactor, 30))
    }
  }, [paymentProviders])

  React.useEffect(() => {
    const container = containerRef.current

    function handleScroll() {
      if (
        container.scrollLeft + container.clientWidth >=
        container.scrollWidth
      ) {
        container.scrollLeft = 0 // Reset scroll position to the start
      }
    }

    function startScroll() {
      const scrollInterval = setInterval(() => {
        container.scrollLeft += 1 // Adjust the scroll speed by changing the increment value
      }, 10) // Adjust the scroll speed by changing the interval value

      return () => clearInterval(scrollInterval)
    }

    container.addEventListener('scroll', handleScroll)
    const scrollInterval = startScroll()

    return () => {
      container.removeEventListener('scroll', handleScroll)
      clearInterval(scrollInterval)
    }
  }, [])

  return (
    <ProviderContainer
      ref={containerRef}
      gridTemplateColumns={[
        `repeat(${Math.ceil(paymentProviders.length / 2)}, 1fr)`,
        `repeat(${Math.ceil(paymentProviders.length)}, 1fr)`,
      ]}
      duration={duration}
    >
      {paymentProviders?.map(item => (
        <LazyLoad once height="30px" key={item.name} offset={100}>
          <Common.Box
            key={item.name}
            as="img"
            alt={item.name}
            src={item.image.url}
          />
        </LazyLoad>
      ))}
    </ProviderContainer>
  )
}
