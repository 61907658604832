import css from '@styled-system/css'
import styled from '@emotion/styled'

export const Logotype = styled.div`
  ${css({
    width: ['117px', null, '190px'],
    height: ['27px', null, '43px'],
    backgroundImage: [
      'url(/images/spinlira-logo-small.svg)',
      null,
      'url(/images/spinlira-logo.svg)',
    ],
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
  })};

  @media screen and (max-width: 420px) {
    background-image: url(/images/spinlira-icon.svg);
    width: 30px;
    height: 30px;
  }
`
// for @loadable/components
export default Logotype
