import * as React from 'react'
import PropTypes from 'prop-types'

import * as common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

import { Amount } from '../amount'

const shine = keyframes`
  0% {
    background-position-x: left;
    color: rgba(255, 255, 255, 0.7);
    transform: scale(1.05, 1.05);
  }

  100% {
    background-position-x: right;
  }
`

const Wrapper = styled('div', {
  shouldForwardProp: common.noneOf(['animate']),
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  text-align: left;
  overflow: hidden;
  ${css({
    backgroundColor: '#142737',
    borderBottomLeftRadius: ['9999px', '0px'],
    borderBottomRightRadius: '9999px',
    borderTopLeftRadius: ['9999px', '0px'],
    borderTopRightRadius: '9999px',
    color: '#93B9E5',
    fontFamily: 'head',
    fontSize: '14px',
    lineHeight: '20px',
    width: ['auto', '100%'],
  })};

  &.animated {
    background-image: linear-gradient(
      140deg,
      rgba(255, 201, 86, 0) 0%,
      rgba(255, 201, 86, 0.75) 35%,
      rgba(251, 224, 167, 0.9) 36%,
      rgba(251, 224, 167, 0.9) 37%,
      rgba(255, 201, 86, 0.75) 50%,
      rgba(255, 201, 86, 0) 75%,
      rgba(255, 201, 86, 0)
    );
    background-repeat: no-repeat;
    background-size: 400% 100%;
    animation-duration: 800ms;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-name: ${shine};
  }
`

export function BalanceWrapper(props) {
  const [isAnimating, setIsAnimating] = React.useState(false)
  const prevBalance = React.useRef(props.amountCents)

  React.useEffect(() => {
    if (isAnimating) {
      const id = setTimeout(() => {
        setIsAnimating(false)
      }, 700)

      return () => {
        clearTimeout(id)
      }
    }
  }, [isAnimating])

  React.useEffect(() => {
    if (props.amountCents > prevBalance.current) {
      setIsAnimating(true)
    }
    prevBalance.current = props.amountCents
  }, [props.amountCents, prevBalance])

  return (
    <Wrapper className={isAnimating ? 'animated' : ''}>
      {props.children}
    </Wrapper>
  )
}

BalanceWrapper.propTypes = {
  amountCents: PropTypes.number,
  children: PropTypes.node,
}

export function Balance(props) {
  return (
    <BalanceWrapper amountCents={props.amountCents}>
      <Amount decimals={props.amountCents > 10000000 ? 'hide' : 'show'}>
        {props.amountCents}
      </Amount>
    </BalanceWrapper>
  )
}

Balance.propTypes = {
  amountCents: PropTypes.number,
  children: PropTypes.node,
}
